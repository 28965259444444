import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type { Registry as ServiceRegistry } from '@ember/service';
import LoginPendingBookingService from 'unattended-showing/services/login-pending-booking';
import Ajax from 'unattended-showing/services/ajax';
import { tracked } from '@glimmer/tracking';
// import GuestSignUp from 'unattended-showing/models/guest-sign-up';
import NotificationManager from '@adc/ui-components/services/notification-manager';
import {
    validatePhoneNumber,
    validateEmail
} from 'unattended-showing/utils/validators';
import type { ModelFrom } from '../../../../customer-site/app/utils/type-utils';
import type GuestSignUpRoute from './route';

/**
 * @classdesc
 * Controller for Guest Sign Up controller.
 */
export default class GuestSignUpController extends Controller {
    @service declare router: ServiceRegistry['router'];
    @service declare ajax: Ajax;
    @service declare store: ServiceRegistry['store'];
    @service declare loginPendingBooking: LoginPendingBookingService;
    @service declare notificationManager: NotificationManager;

    declare model: ModelFrom<GuestSignUpRoute>;

    @tracked firstName: string = '';
    @tracked lastName: string = '';
    @tracked email: string = '';
    @tracked phoneNumber: string = '';

    @action formatPhoneNumber(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        let formattedNumber = input.value;

        // Remove non-numeric characters.
        formattedNumber = formattedNumber.replace(/\D/g, '');

        // Ensure number is not longer than 10 digits.
        if (formattedNumber.length > 10) {
            formattedNumber = formattedNumber.substring(0, 10);
        }

        // Add dashes to the phone number.
        if (formattedNumber.length > 3 && formattedNumber.length <= 6) {
            formattedNumber = formattedNumber.replace(/(\d{3})(\d+)/, '$1-$2');
        } else if (formattedNumber.length > 6) {
            formattedNumber = formattedNumber.replace(
                /(\d{3})(\d{3})(\d+)/,
                '$1-$2-$3'
            );
        }

        this.phoneNumber = formattedNumber;
    }

    /**
     * Sends user info to server.
     * If successful, transition to verify phone number page.
     */
    @action
    async signupAsGuest() {
        //Check that the user inputs are valid.
        try {
            if (!this.firstName || this.firstName.trim() === '') {
                this.notificationManager.addError('First name is required.');
                return;
            }

            if (this.firstName.length > 100) {
                this.notificationManager.addError(
                    'First name cannot be longer than 100 characters.'
                );
                return;
            }

            if (!this.lastName || this.lastName.trim() === '') {
                this.notificationManager.addError('Last name is required.');
                return;
            }

            if (this.lastName.length > 100) {
                this.notificationManager.addError(
                    'Last name cannot be longer than 100 characters.'
                );
                return;
            }

            if (!validatePhoneNumber(this.phoneNumber)) {
                this.notificationManager.addError(
                    'Valid U.S. phone number is required.'
                );
                return;
            }

            if (!validateEmail(this.email)) {
                this.notificationManager.addError('Valid email is required.');
                return;
            }

            if (this.email.length > 100) {
                this.notificationManager.addError(
                    'Email cannot be longer than 100 characters.'
                );
                return;
            }

            this.model.guestSignUp = this.store.createRecord('guest-sign-up', {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phoneNumber: this.phoneNumber,
                systemGroupId: this.model.context?.systemGroupId
            });
            this.model.guestSignUp = await this.model.guestSignUp.save();

            /**
             * Do nothing.
             * We will transition to the Verify Phone page when after it is created.
             */
        } catch (error) {
            return this.ajax.handleAjaxError(
                error,
                'Unable to sign up as guest.'
            );
        }
    }

    /**
     * Transitions route back to the login page.
     */
    @action async back(): Promise<void> {
        try {
            this.router.transitionTo('context.login-sgt3');
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to go to login page. Please try again later.'
            );
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'context/guest-sign-up': GuestSignUpController;
    }
}
