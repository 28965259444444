import templateOnlyComponent from '@ember/component/template-only';

export interface SimpleListChevronValueSignature {
    Element: HTMLParagraphElement;
    Args: {
        hideArrow?: boolean;
    };
    Blocks: {
        default: [];
    };
}

const SimpleListChevronValue = templateOnlyComponent<SimpleListChevronValueSignature>();
export default SimpleListChevronValue;
