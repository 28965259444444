import Component from '@glimmer/component';

import type { ColorName } from '../utils/type-utils';

export interface ChipSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to display. */
        text: string;
        /** Optional icon to display. */
        icon?: string;
        /** An optional status color (defaults to transparent). */
        bgColor?: ColorName;
        /** Indicates the text should be on the left, followed by the icon. */
        reversed?: boolean;
    };
}

export default class Chip extends Component<ChipSignature> {
    /**
     * A computed CSS class based on the optional background color.
     */
    get bgColor(): string {
        const { bgColor } = this.args;
        return bgColor ? `adc-${bgColor}-bg` : '';
    }
}
