import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { bool } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import { guidFor } from '@ember/object/internals';
import { task } from 'ember-concurrency';

import type IntlService from '@adc/i18n/services/adc-intl';
import type RouterService from '@ember/routing/router-service';
import type DomService from '@adc/ember-utils/services/dom';
import type { PopoverMenuSignature } from './popover-menu';
import type { PopoverHeaderSignature } from './popover/popover-header';
import type { Task } from 'ember-concurrency';

type PopoverMenuSignatureArgs = PopoverMenuSignature['Args'];

export class SystemSelectItem {
    @tracked name: string;
    @tracked description: string;
    @tracked icon: string;
    @tracked selected: boolean;
    @tracked disabled: boolean;
    @tracked showSubItemsOnOpen: boolean;
    @tracked isSystemParentAccountSuspended: boolean;
    @tracked tooltipText: string;
    @tracked subItems?: SystemSelectItem[];
    action?: VoidFunction;

    @tracked showSubItems = false;
    @tracked show = false;

    constructor(props: Partial<SystemSelectItem>) {
        this.name = props.name ?? '';
        this.description = props.description ?? '';
        this.icon = props.icon ?? '';
        this.selected = props.selected ?? false;
        this.disabled = props.disabled ?? false;
        this.showSubItemsOnOpen = props.showSubItemsOnOpen ?? false;
        this.isSystemParentAccountSuspended = props.isSystemParentAccountSuspended ?? false;
        this.tooltipText = props.tooltipText ?? '';
        this.subItems = props.subItems;
        this.action = props.action;
    }
}

/**
 * Set this parameter to true on open to avoid sending actions for focused elements
 */
let preventKeyUpOnTrigger: boolean;

/**
 * Handle document keyboard events when the dropdown is open.
 *
 * @private
 */
function onGlobalKeyUp(this: SystemSelect, event: KeyboardEvent): void {
    // Are there no items?
    const items = this.itemsInternal;
    if (!items?.length) {
        return;
    }

    const activeElementDataIndex = (document.activeElement as HTMLElement).dataset.index;
    if (!activeElementDataIndex) {
        return;
    }

    // If the active element is an item from the list, get the item index
    const [mainItemIndex, subItemIndex = null] = activeElementDataIndex.split('-').map((s) => Number(s));

    const { code } = event;
    if (!isEnterOrSpaceCode(code)) {
        return;
    }

    event.preventDefault();

    let currentItem;

    // Check if the current item is a subitem or a main item
    if (subItemIndex) {
        currentItem = items[mainItemIndex].subItems?.[subItemIndex];
    } else if (mainItemIndex) {
        currentItem = items[mainItemIndex];
    }

    if (currentItem?.disabled) {
        return;
    }

    // Did the user hit the enter or space key?
    // If element is not in the list or the key is not Enter or Space, we prevent executing the wrong action.
    if (isEnterOrSpaceCode(code) && currentItem && !preventKeyUpOnTrigger) {
        const { action } = currentItem;
        if (action) {
            action();
            this.isOpen = false;
        }

        return;
    }

    preventKeyUpOnTrigger = false;
}

/**
 * Counts the items including their subitems
 *
 * @private
 */
function countItems(items: SystemSelectItem[] = []): number {
    return items.reduce((count, { subItems = [] }) => count + subItems.length, items.length);
}

export interface SystemSelectSignature {
    Element: HTMLButtonElement;
    Args: Pick<PopoverMenuSignatureArgs, 'placement' | 'maxWidth' | 'maxHeight'> & {
        /** The SystemSelectItems to render. */
        items?: SystemSelectItem[] | Promise<SystemSelectItem[]>;
        /** Optional CSS class added to the popover element. */
        dropdownMenuClass?: PopoverMenuSignatureArgs['popoverClass'];
        /** Optional placeholder text for the search input. */
        searchPlaceholder?: string;
        /** The number of items required to show the search box (defaults to 11). */
        numberOfItemsForSearchToAppear?: number;
        /** The text to render in the popover header. */
        title?: PopoverHeaderSignature['Args']['title'];
        /** `-1` if the element is interactive. */
        tabindex?: '0' | '-1';
        /** Called when the popover menu opens. */
        'on-open'?: VoidFunction;
        /** Called when the popover menu closes. */
        'on-close'?: VoidFunction;
        /** Called when the user searches. */
        'search-change'?: (value: string) => void;
        /** Called when the popover opens. */
        'on-create-dropdown'?: PopoverMenuSignatureArgs['on-create-popover'];
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * Dropdown menu for selecting ADC system/group.
 */
export default class SystemSelect extends Component<SystemSelectSignature> {
    @service declare intl: IntlService;
    @service declare media: ADCResponsiveMediaService;
    @service declare router: RouterService;
    @service declare dom: DomService;

    /**
     * The number of elements to show before the search box is visible.
     */
    get numberOfItemsForSearchToAppear(): number {
        return this.args.numberOfItemsForSearchToAppear ?? 11;
    }

    /**
     * Indicates a search function was passed to this component.
     */
    @bool('args.search-change')
    declare hasSearchAction: boolean;

    /**
     * Should the element be focusable?
     */
    @computed('args.tabindex')
    get isInteractive(): boolean {
        return this.args.tabindex !== '-1';
    }

    /**
     * Indicates the menu is open.
     */
    @tracked isOpen = false;

    /**
     * Width for the menu.
     */
    @tracked width?: number;

    @tracked itemsInternal?: SystemSelectItem[];
    @tracked searchHeight?: number;

    /**
     * maxHeight with the searchHeight subtracted.
     * If there is no search bar, then it equals maxHeight.
     */
    @computed('args.maxHeight', 'searchHeight')
    get maxHeightWithoutSearch(): number | undefined {
        const { searchHeight = 0 } = this,
            { maxHeight } = this.args;

        if (!maxHeight || !searchHeight) {
            return maxHeight;
        }

        return maxHeight - searchHeight;
    }

    /**
     * Search value.
     */
    @tracked searchValue = '';

    /**
     * The selector of the element which should receive focus after the menu opens.
     */
    @tracked focusSelector = '';

    keyUpListener?: string;

    /**
     * Should the search input be visible even if the conditions are not met (in case the number of items changes because of the search)
     */
    @tracked keepSearchVisible = false;

    /**
     * Should the search input be shown?
     */
    @computed('itemsInternal', 'keepSearchVisible', 'hasSearchAction', 'numberOfItemsForSearchToAppear')
    get showSearch(): boolean {
        const count = this.hasSearchAction ? countItems(this.itemsInternal) : 0;
        return this.keepSearchVisible || count >= this.numberOfItemsForSearchToAppear;
    }

    /**
     * Should the title wrapper be shown?
     */
    @computed('args.title', 'media.isMobile')
    get showTitleWrap(): boolean {
        const { isMobile } = this.media;
        if (!this.args.title && isMobile) {
            console.error("A system-select title string should be rendered but it's missing.");

            return false;
        }

        return isMobile;
    }

    /**
     * Called to create the internal items.
     */
    createInternalItems: Task<void, never> = task({ restartable: true }, async () => {
        const items: SystemSelectItem[] = (await this.args.items) ?? [],
            fnDetermineWhetherToShowItem = (items: SystemSelectItem[], showItems: boolean): SystemSelectItem[] => {
                return items.map((item) => {
                    item.show = showItems || item.selected;

                    const { subItems, showSubItemsOnOpen = false } = item;

                    if (subItems) {
                        item.showSubItems = showSubItemsOnOpen;
                        item.subItems = fnDetermineWhetherToShowItem(subItems, showSubItemsOnOpen);
                    }

                    return item;
                });
            };

        this.itemsInternal = fnDetermineWhetherToShowItem(items, true);
    });

    /**
     * Toggles the dropdown
     */
    @action toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Handling opening of the popover menu
     */
    @action onOpen(): void {
        this.keyUpListener = this.dom.addListener(this, document, 'keyup', onGlobalKeyUp.bind(this), false, true);

        preventKeyUpOnTrigger = true;
        // The listener and preventKeyUpOnTrigger parameter will be removed in the onClose action.

        this.args['on-open']?.();
    }

    /**
     * Handling closing of the popover menu
     */
    @action onClose(): void {
        this.searchValue = '';

        const { keyUpListener } = this;
        if (keyUpListener) {
            this.dom.removeListener(this, keyUpListener);
            this.keyUpListener = undefined;
        }

        // This function recursively closes all sub-items when the system-select is closed.
        const fnCloseSubItems = (items?: SystemSelectItem[]): void => {
            items?.forEach((item) => {
                if (item.subItems) {
                    // Make the item's collapsible icon show that the sub-items are collapsed.
                    item.showSubItems = false;

                    const { subItems = [] } = item;

                    // Hide each sub-item (unless it is selected).
                    subItems.forEach((subItem) => (subItem.show = subItem.selected));

                    // Recursively perform the hiding steps on this item's sub-items.
                    fnCloseSubItems(subItems);
                }
            });
        };

        // Close all sub-items.
        fnCloseSubItems(this.itemsInternal);

        this.args['on-close']?.();

        document.getElementById(guidFor(this))?.focus();
        preventKeyUpOnTrigger = false;
    }

    @action configurePopup(elList: HTMLUListElement): void {
        const cssSelected = 'li[aria-selected="true"] a';
        elList.querySelector(cssSelected)?.scrollIntoView();

        if (this.showSearch) {
            if (this.width === undefined) {
                const width = elList.closest('.system-select-menu')?.clientWidth;
                if (width) {
                    this.width = width;
                }
            }
        }
    }

    @action configureSearch(elSearchWrapper: HTMLDivElement): void {
        this.searchHeight = elSearchWrapper.offsetHeight;
        this.updateFocusSelector('input[type="search"]');
    }

    private updateFocusSelector(v: string): void {
        this.focusSelector = `.system-select-menu ${v}`;
    }

    /**
     * Triggered when search value has been changed
     */
    @action doSearchAction(value: string): void {
        // Update the search value
        this.searchValue = value;

        const fn = this.args['search-change'];

        // Do not trigger search if there is no search function or if the dropdown is closed
        if (fn && this.isOpen) {
            fn(value);
            this.keepSearchVisible = true;
        }
    }
}
