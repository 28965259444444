import BaseJsonApiContentAdapter from './base-json-api-content-adapter.ts';
import { inject as service } from '@ember/service';
import { NotSet } from '../enums/MicroApi.ts';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import isBefore from 'date-fns/isBefore';
import RSVP from 'rsvp';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import type ContextManager from '../services/context-manager';
import type MicroApiEndpoint from '../models/micro-api-endpoint';
import type Store from '@ember-data/store';
import type BaseModel from '../models/base-model';

interface HeaderProps {
    Accept: string;
    Authorization: string;
    SourcePath: string;
    SourceQueryParams: string;
    TokenVersion: string;
    'content-type': string;
}

/**
 * @classdesc
 * Custom Adapter for Micro API services
 */
export default class BaseMicroApiAdapter extends BaseJsonApiContentAdapter {
    @service declare contextManager: ContextManager;
    @service declare store: Store;

    /**
     * An endpoint item is selected per adapter based on this value
     */
    microApiEnum = NotSet;

    /**
     * The MicroApi endpoint path name
     */
    microApiEndpointPath = '';

    /**
     * Get the data for this endpoint
     */
    @tracked
    endpointData?: MicroApiEndpoint;

    /**
     * The token version
     */
    @tracked
    tokenVer?: string;

    /** @override */
    namespace = '';

    /** @override */
    host = '';

    /** @override */
    @computed('endpointData.encodedJwtToken', 'tokenVer')
    get headers(): HeaderProps {
        return this.buildHeaderProps(this.endpointData?.encodedJwtToken ?? '');
    }

    /** @override */
    findAll<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        sinceToken: string,
        snapshot: DS.SnapshotRecordArray<keyof ModelRegistry>
    ): RSVP.Promise<ModelRegistry[K][]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.findAll(store, type, sinceToken, snapshot).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    findRecord<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        id: string,
        snapshot: DS.Snapshot<K>
    ): RSVP.Promise<ModelRegistry[K]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.findRecord(store, type, id, snapshot).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    query<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        query: Record<string, string | number | boolean>
    ): RSVP.Promise<ModelRegistry[K][]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.query(store, type, query).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    queryRecord<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        query: Record<string, string | number | boolean>
    ): RSVP.Promise<ModelRegistry[K]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.queryRecord(store, type, query).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    updateRecord<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        snapshot: DS.Snapshot<K>
    ): RSVP.Promise<ModelRegistry[K]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.updateRecord(store, type, snapshot).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    createRecord<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        snapshot: DS.Snapshot<K>
    ): RSVP.Promise<ModelRegistry[K]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.createRecord(store, type, snapshot).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    deleteRecord<K extends keyof ModelRegistry>(
        store: Store,
        type: ModelRegistry[K],
        snapshot: DS.Snapshot<K>
    ): RSVP.Promise<ModelRegistry[K]> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    resolve(super.deleteRecord(store, type, snapshot).catch(() => reject()));
                })
                .catch(() => reject());
        });
    }

    /** @override */
    submitCustomApiCall(model: BaseModel, verb: string, method = 'POST', rawData: any): Promise<any> {
        return new RSVP.Promise((resolve, reject) => {
            this.ensureEndpointAndToken()
                .then(() => {
                    super
                        .submitCustomApiCall(model, verb, method, rawData)
                        .then((value) => resolve(value))
                        .catch(() => reject());
                })
                .catch(() => reject());
        });
    }

    /** @override */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return this.microApiEndpointPath;
    }

    /**
     * Check that we have a valid endpoint and token, get them if we don't
     */
    protected async ensureEndpointAndToken(): Promise<void> {
        if (!this.endpointData || !this.tokenVer) {
            const microApiData =
                    this.store.peekAll('micro-api-data-item').firstObject ??
                    (await this.store.findAll('micro-api-data-item')).firstObject,
                endpointData = microApiData?.microApiEndpoints.find(
                    (endpoint: MicroApiEndpoint) => endpoint.id === String(this.microApiEnum)
                );

            Object.assign(this, {
                endpointData,
                namespace: this.buildNamespace(endpointData?.namespace ?? ''),
                host: this.buildHost(endpointData?.baseApiEndpoint ?? ''),
                tokenVer: microApiData?.microApiTokenVer
            });
        }

        const now = new Date();
        // We want to give 60 seconds of padding before expiration time to not hit any race conditions
        if (this.endpointData && isBefore(this.endpointData.jwtExpirationDate, now.setSeconds(now.getSeconds() - 60))) {
            await this.endpointData.reload();
        }
    }

    /**
     * Build the properties for the headers
     */
    private buildHeaderProps(token: string): HeaderProps {
        const { pathname, search } = window.location;

        return {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${token}`,
            SourcePath: pathname,
            SourceQueryParams: search,
            TokenVersion: this.tokenVer ?? '',
            'content-type': 'application/vnd.api+json'
        };
    }

    /**
     * Determine the namespace based on if versioning is provided from the endpoint data or overridden manually
     * in the adapter.
     */
    protected buildNamespace(namespaceData: string) {
        return namespaceData || this.namespace;
    }

    /**
     * Determine the host url. Must start with 'https://' to avoid automatic CustomerDotNet route prefixing.
     */
    protected buildHost(hostData: string) {
        return hostData && !hostData.startsWith('https://') ? `https://${hostData}` : hostData;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'base-micro-api-adapter': BaseMicroApiAdapter;
    }
}
