import ProtectedRoute from 'unattended-showing/classes/protected-route';
import { inject as service } from '@ember/service';

/**
 * @classdesc
 * Route for user profile.
 */
export default class ProfileRoute extends ProtectedRoute {
    @service sgtSession;
    @service store;

    /**
     * Returns profile model.
     *
     * @type {{profile: (models.Profile|promise)}}
     */
    async model() {
        const countries = await this.store.findAll('country');

        return {
            profile: this.store.findRecord(
                'profile',
                this.sgtSession.data.authenticated.profileId
            ),
            countries
        };
    }

    /**
     * Rollback model if not saving when exiting route.
     */
    async deactivate() {
        const profile = await this.currentModel.profile;
        if (profile.hasDirtyAttributes && !profile.isSaving) {
            profile.rollbackAttributes();
        }
    }
}
