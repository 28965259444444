import EmberRouter from '@embroider/router';
import config from 'unattended-showing/config/environment';

export default class Router extends EmberRouter {
    location = config.locationType;
    rootURL = config.rootURL;
}

Router.map(function () {
    this.route('context', { path: '/:context_id' }, function () {
        this.route('login');
        this.route('logout');
        this.route('forgot-password');
        this.route('sign-up', function () {
            this.route('verification');
        });
        this.route('profile');
        this.route('appointment', { path: '/' });
        this.route('location', { path: '/location/:location_id' });
        this.route('loading');
        this.route('change-password');
        this.route('faq');
        this.route('available-units-list', {
            path: '/bookings/:available_units_list_id'
        });
        this.route('redirect', { path: '/redirect/:external_identifier_id' });
        this.route('not-found', { path: '/*path' });
        this.route('available-units-list-sgt3', {
            path: '/bookings-sgt3/:available_units_list_id'
        });
        this.route('location-sgt3', { path: '/location-sgt3/:location_id' });
        this.route('login-sgt3');
        this.route('sign-up-sgt3', function () {
            this.route('credit-card-verification');
        });
        this.route('appointment-sgt3');
        this.route('forgot-password-sgt3');
        this.route('change-password-sgt3');
        this.route('faq-sgt3');
        this.route('profile-sgt3');
        this.route('guest-sign-up');
        this.route('guest-phone-verification');
    });
    this.route('not-found', { path: '/*path' });
});
