import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type NativeBridgeService from './native-bridge';

export default class BrowserService extends Service {
    @service declare nativeBridge: NativeBridgeService;

    /**
     * Copies the given message to the clipboard.
     */
    async copyToClipboard(message: string): Promise<boolean> {
        // Create a DOM element, add the text, copy it to clipboard, then remove it
        const textArea = document.createElement('textarea');
        textArea.style.visibility = 'hidden';
        if (!message) {
            return false;
        }

        textArea.value = message;

        document.body.appendChild(textArea);
        textArea.select();

        try {
            await navigator.clipboard.writeText(message);
            document.body.removeChild(textArea);
            return true;
        } catch {
            return false;
        }
    }

    /**
     * Correctly copies to clipboard depending
     * on what device/platform is in use.
     */
    async universalCopyToClipboard(subject: string, anchorElement: HTMLElement, message: string): Promise<boolean> {
        // Mainly for iOS.
        // doesSupportLaunchShareActionDialog fulfills the check for isMobileApp.
        if (this.nativeBridge.doesSupportLaunchShareActionDialog) {
            try {
                await this.nativeBridge.launchShareActionDialog(subject, message, anchorElement);
                return true;
            } catch {
                return false;
            }
        }

        // Desktop only.
        return (async () => {
            return this.copyToClipboard(message);
        })();
    }
}
