import Helper from '@ember/component/helper';
import { hash } from 'rsvp';

type Resolved<P> = P extends Promise<infer T> ? T : P;

type AwaitedObject<T> = {
    [K in keyof T]: Resolved<T[K]>;
};

interface ResolveModelSignature<T> {
    Args: {
        Positional: [T];
    };
    Return: Promise<AwaitedObject<T>>;
}

export default class ResolveModelHelper<
    T extends Record<string, Promise<any> | any>
> extends Helper<ResolveModelSignature<T>> {
    compute([obj]: [T]): ResolveModelSignature<T>['Return'] {
        // Hack to fix https://github.com/emberjs/ember.js/issues/19277.
        Object.keys(obj).forEach((k) => obj[k]);
        const foo = hash<AwaitedObject<T>>(obj);
        return foo;
    }
}
