import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Route for logging out user.
 */
export default class LogoutRoute extends Route {
    @service router;
    @service sgtSession;
    @service loginPendingBooking;

    /**
     * Logs out current user by invalidating session.
     */
    beforeModel() {
        const { sgtSession } = this,
            contextId = this.paramsFor('context').context_id;

        if (sgtSession.isAuthenticated) {
            Object.assign(sgtSession.data, { contextId });
            this.loginPendingBooking.resetAppointmentData();
            sgtSession.invalidate();
            return;
        }

        this.router.transitionTo('context.login', contextId);
    }
}
