import BaseRoute from 'unattended-showing/classes/base-route';
import { inject as service } from '@ember/service';

/**
 * @classdesc
 * Base class for all protected routes which ensures a user is logged in.
 */
export default class ProtectedRoute extends BaseRoute {
    @service sgtSession;

    beforeModel(transition) {
        this.sgtSession.requireAuthentication(transition, 'context.login');
    }
}
