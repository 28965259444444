import Route from '@ember/routing/route';
import Context from '../../models/context';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';
import SGTSession from 'unattended-showing/services/sgt-session';

interface ContextModel {
    context: Promise<Context>;
}

/**
 * @classdesc Route for unattended property locationsSGT3.
 */
export default class LocationSgt3Route extends Route {
    @service declare sgtSession: SGTSession;
    @service declare store: ServiceRegistry['store'];
    @service declare router: ServiceRegistry['router'];

    /**
     * @param model
     * @returns {{location: (models.location|promise), context: (models.context|promise)}}
     */
    async model(model: { location_id: string | number }) {
        try {
            const contextModel = this.modelFor('context') as ContextModel;
            const context = await contextModel.context;
            const location = await this.store.findRecord(
                'location',
                model.location_id,
                {
                    adapterOptions: {
                        queryParams: { systemGroupId: context.systemGroupId }
                    }
                }
            );
            let profile = null;
            const profileId = this.sgtSession.data?.authenticated?.profileId;
            if (profileId) {
                profile = await this.store.findRecord('profile', profileId);
            }

            return {
                context,
                location,
                profile
            };
        } catch (error) {
            this.send('error', error);

            return {};
        }
    }

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
