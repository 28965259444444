/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action, computed } from '@ember/object';
import { BinaryListItem } from '@adc/ui-components/components/simple-binary/list';
import { datesAreOnSameDay } from 'unattended-showing/utils/datetime-utils';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { utcToZonedTime } from 'date-fns-tz';
import Component from '@glimmer/component';
import DropdownSelectItem from '@adc/ui-components/utils/dropdown-select-item';
import {
    addDays,
    addMinutes,
    format,
    isAfter,
    isBefore,
    isEqual,
    isWithinInterval,
    startOfDay
} from 'date-fns';

import type { Registry as ServiceRegistry } from '@ember/service';
import type ArrayProxy from '@ember/array/proxy';
import type LocationModel from 'unattended-showing/models/location';
import type MockTimeService from 'unattended-showing/services/mock-time';
import type SanitizedAppointment from 'unattended-showing/models/sanitized-appointment';

interface BookTourArgs {
    appointmentSettings: AppointmentSettings;
    location: LocationModel;
    targetMoveInDate?: Date;
    nextAction: (tourDateTime: Date, desiredMoveInDate: Date) => void;
}

interface AppointmentSettings {
    appointmentLength: number;
    hoursOfOperationSchedule: Timeblock[];
}

interface Timeblock {
    day: number;
    startMinutesLocal: number;
    endMinutesLocal: number;
}

interface DateListItemProps {
    date: Date;
    relativeDate: string;
    timeSlotOptions: DropdownSelectItem[];
}

interface TimeSlot {
    startDateTime: Date;
    endDateTime?: Date;
    available: boolean;
    currentTimeSlot: boolean;
}

interface UnavailablePeriod {
    startTime: Date;
    isTourNow: boolean;
}

export default class BookTour extends Component<BookTourArgs> {
    @service declare mockTime: MockTimeService;
    @service
    declare notificationManager: ServiceRegistry['notification-manager'];

    @tracked tourDate = new Date();
    @tracked tourTime = '';
    @tracked desiredMoveInDate = this.desiredMoveInDateInitalValue;
    @tracked timeSlotSelectOptions: DropdownSelectItem[] = [];

    /**
     * Get the initial value to be used in the Desired Move-In Date datepicker. This value will come from the user's account if they are signed in.
     * @returns Previously saved desired move-in date if the user is signed in, or null if they are not.
     */
    get desiredMoveInDateInitalValue(): Date | null {
        return this.args.targetMoveInDate ?? null;
    }

    /**
     * Returns upcoming days for which appointment time slots can be booked.
     * @return List of BinaryListItems used to display Tour Date option buttons.
     */
    @computed(
        'args.location.{timeZone,unavailablePeriods}',
        'maxTourDates',
        'mockTime.mockTime',
        'unavailablePeriods'
    )
    get appointmentDays(): Promise<BinaryListItem<DateListItemProps>[]> {
        return (async () => {
            const { timeZone } = this.args.location || {},
                maxTourDates: number = 7;

            // Get time slots which are unavailable.
            const unavailablePeriodModelsUTC = await this.args.location
                    .unavailablePeriods,
                unavailablePeriodsLocal: UnavailablePeriod[] =
                    this.getUnavailablePeriods(
                        unavailablePeriodModelsUTC,
                        timeZone
                    );

            // Generate the date list items to display on the form.
            const daySlots: BinaryListItem<DateListItemProps>[] = [];
            for (let i = 0; i < maxTourDates; i++) {
                daySlots.push(
                    this.getTourDateBinaryListItem(
                        i,
                        timeZone,
                        unavailablePeriodsLocal
                    )
                );
            }

            // Set the auto-selected option to the first enabled date
            const firstAvailableDate = daySlots.find((dateItem) => {
                return !dateItem.disabled;
            });
            if (firstAvailableDate) {
                firstAvailableDate.state = true;
                this.setSelectedTourDate(firstAvailableDate);
            }

            return daySlots;
        })();
    }

    /**
     * Builds a binary list option for a tour date.
     * @returns A BinaryListItem to use to display an option button for a tour date.
     */
    getTourDateBinaryListItem(
        index: number,
        timeZone: string,
        unavailablePeriodsLocal: UnavailablePeriod[]
    ): BinaryListItem<DateListItemProps> {
        const currentUnitDateLocal = this.getCurrentUnitDateLocal(timeZone);
        const today = startOfDay(currentUnitDateLocal);
        const day: Date = addDays(today, index);
        const timeSlotOptions = this.getAppointmentTimeSlots(
            day,
            unavailablePeriodsLocal
        );
        return new BinaryListItem<DateListItemProps>({
            disabled: !this.hasAvailableTimeSlots(timeSlotOptions),
            props: {
                date: day,
                relativeDate: this.getRelativeDateText(day, today),
                timeSlotOptions: timeSlotOptions
            }
        });
    }

    /**
     * Checks a list of time slot dropdown options to see if at least one is available.
     * @returns True if at least one option is available to book.
     */
    hasAvailableTimeSlots(timeSlotOptions: DropdownSelectItem[]): boolean {
        if (timeSlotOptions.length < 1) {
            return false;
        }
        const availableTimeSlot = timeSlotOptions.find((option) => {
            return !option.disabled;
        });
        return availableTimeSlot !== undefined;
    }

    /**
     * Get the 'Today', 'Tomorrow', or 'Future' language for a Tour Date button.
     * @returns Language to display on the button.
     */
    getRelativeDateText(buttonDate: Date, today: Date): string {
        if (isEqual(buttonDate, today)) {
            return 'Today';
        } else if (isEqual(buttonDate, addDays(today, 1))) {
            return 'Tomorrow';
        }
        return 'Future';
    }

    /**
     * Update the selected tour date when it is changed.
     */
    @action
    onTourDateChange(
        items: BinaryListItem<DateListItemProps>[]
    ): Promise<void> {
        return (async () => {
            const appointmentDays: BinaryListItem<DateListItemProps>[] =
                await this.appointmentDays;
            const selectedItem =
                items.find((item) => item.state) ?? appointmentDays[0];
            this.setSelectedTourDate(selectedItem);
        })();
    }

    /**
     * Sets the tour date and time slot options based on the given selected date button.
     */
    setSelectedTourDate(selectedItem: BinaryListItem<DateListItemProps>): void {
        this.tourDate = selectedItem.props.date;
        this.timeSlotSelectOptions = selectedItem.props.timeSlotOptions;
    }

    /**
     * Get a list of time slot options for the Tour Time dropdown for a given date.
     * @returns List of options to use in the Tour Time select dropdown.
     */
    getAppointmentTimeSlots(
        date: Date,
        unavailablePeriodsLocal: UnavailablePeriod[]
    ): DropdownSelectItem[] {
        const { timeZone } = this.args.location || {},
            { appointmentLength } = this.args.appointmentSettings,
            currentUnitDateLocal = this.getCurrentUnitDateLocal(timeZone),
            timeSlotsLocal = this.getTimeSlotsLocal(date, currentUnitDateLocal);

        timeSlotsLocal
            .filter((timeSlot) => {
                return !this.isTimeSlotAvailable(
                    timeSlot.startDateTime,
                    unavailablePeriodsLocal,
                    appointmentLength
                );
            })
            .forEach((t) => set(t, 'available', false));

        // Check if the first time slot is currently ongoing.
        if (timeSlotsLocal.length > 0) {
            if (
                this.hasStartTimePassed(timeSlotsLocal[0], currentUnitDateLocal)
            ) {
                timeSlotsLocal[0].currentTimeSlot = true;

                // If the first time slot is current and the second is unavailable, mark the first as unavailable as well.
                if (timeSlotsLocal.length > 1 && !timeSlotsLocal[1].available) {
                    timeSlotsLocal[0].available = false;
                }
            }
        }

        return timeSlotsLocal.map((timeSlot) => {
            const timeSlotLabel: string =
                this.formatTimeSlotForDropdown(timeSlot);
            return DropdownSelectItem.create({
                name: timeSlotLabel,
                value: timeSlot.startDateTime.toString(),
                disabled: !timeSlot.available
            });
        });
    }

    /**
     * Checks if the given time slot has begun.
     * @returns True if the time slot's start time has already occurred.
     */
    hasStartTimePassed(timeSlot: TimeSlot, currentDateTime: Date): boolean {
        return (
            isAfter(currentDateTime, timeSlot.startDateTime) ||
            isEqual(currentDateTime, timeSlot.startDateTime)
        );
    }

    /**
     * Get the periods that are unavailable for tours.
     * @returns List of UnavailablePeriods.
     */
    getUnavailablePeriods(
        unavailablePeriodModelsUTC: ArrayProxy<SanitizedAppointment>,
        timeZone: string
    ): UnavailablePeriod[] {
        return unavailablePeriodModelsUTC.map((unavailablePeriodModel) => {
            const dateTimeUTC = new Date(unavailablePeriodModel.dateTimeUtc),
                dateTimeLocal = utcToZonedTime(dateTimeUTC, timeZone),
                isTourNow = unavailablePeriodModel.isTourNow;
            return {
                startTime: dateTimeLocal,
                isTourNow: isTourNow
            };
        });
    }

    /**
     * Checks whether the given time slot is available (does not conflict with unavailable periods).
     * @returns True if the time slot does not conflict with the unavailable periods.
     */
    isTimeSlotAvailable(
        timeSlotStart: Date,
        unavailablePeriods: UnavailablePeriod[],
        appointmentLength: number
    ): boolean {
        return unavailablePeriods.every((unavailablePeriod) => {
            const timeSlotEnd = this.getAppointmentEndDate(
                    timeSlotStart,
                    appointmentLength
                ),
                unavailablePeriodEnd = this.getAppointmentEndDate(
                    unavailablePeriod.startTime,
                    unavailablePeriod.isTourNow
                        ? appointmentLength * 2
                        : appointmentLength
                );

            if (
                isEqual(timeSlotStart, unavailablePeriodEnd) ||
                isEqual(timeSlotEnd, unavailablePeriod.startTime)
            ) {
                return true;
            }

            const unavailableInterval = {
                    start: unavailablePeriod.startTime,
                    end: unavailablePeriodEnd
                },
                startAvailable = !isWithinInterval(
                    timeSlotStart,
                    unavailableInterval
                ),
                endAvailable = !isWithinInterval(
                    timeSlotEnd,
                    unavailableInterval
                );

            return startAvailable && endAvailable;
        });
    }

    /**
     * Get the string to show for a time slot in the Tour Time dropdown.
     * @returns Formatted string to display in Tour Time dropdown.
     */
    formatTimeSlotForDropdown(timeSlot: TimeSlot): string {
        if (timeSlot.currentTimeSlot) {
            return 'Tour Now';
        }
        return timeSlot.startDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: '2-digit'
        });
    }

    /**
     * Get the upcoming and current time slots for the given day.
     * @return List of upcoming and current time slots.
     */
    getTimeSlotsLocal(targetDay: Date, currentUnitDateLocal: Date): TimeSlot[] {
        let timeSlotsLocal: TimeSlot[] = [];

        // If the property is not getting their available times from a CRM integration, generate them.
        if (this.args.location.appointmentTimeSlotsFromCrmLocal == undefined) {
            timeSlotsLocal = this.generateTimeSlotsForDay(
                targetDay,
                currentUnitDateLocal
            );
        } else {
            timeSlotsLocal = this.getTimeSlotsFromCrmLocal(
                targetDay,
                currentUnitDateLocal
            );
        }

        return timeSlotsLocal.filter((slot) => slot.available);
    }

    /**
     * Generates all time slots, available and unavailable, for a given day based on the hours of operation schedule.
     * @returns List of time slots for the given day.
     */
    generateTimeSlotsForDay(
        targetDay: Date,
        currentUnitDateLocal: Date
    ): TimeSlot[] {
        const { appointmentLength, hoursOfOperationSchedule } =
            this.args.appointmentSettings;

        // Get the timeblocks for the selected day.
        const filteredTimeblocks = hoursOfOperationSchedule?.filter(
                (timeblock) => timeblock.day === targetDay.getDay()
            ),
            timeSlots: TimeSlot[] = [];

        // Iterate through the timeblocks and add appointment slots.
        filteredTimeblocks?.forEach(
            ({ startMinutesLocal, endMinutesLocal }) => {
                for (
                    let currentTime = startMinutesLocal;
                    currentTime <= endMinutesLocal;
                    currentTime += appointmentLength
                ) {
                    const startDateTime = new Date(targetDay);
                    startDateTime.setMinutes(currentTime);
                    const endDateTime = this.getAppointmentEndDate(
                        new Date(startDateTime),
                        appointmentLength
                    );
                    timeSlots.push({
                        startDateTime,
                        available: isAfter(endDateTime, currentUnitDateLocal),
                        currentTimeSlot: false
                    });
                }
            }
        );

        return timeSlots;
    }

    /**
     * Generates all time slots, available and unavailable, based on time slots given by the CRM.
     * @returns List of time slots for the given day.
     */
    getTimeSlotsFromCrmLocal(
        targetDay: Date,
        currentUnitDateLocal: Date
    ): TimeSlot[] {
        const timeSlots: TimeSlot[] = [];

        this.args.location.appointmentTimeSlotsFromCrmLocal.forEach(
            (slot: string) => {
                const startDateLocal = new Date(slot);
                const endDateLocal = this.getAppointmentEndDate(
                    startDateLocal,
                    this.args.appointmentSettings.appointmentLength
                );

                // Filter out time slots for different days and time slots that have already ended.
                if (
                    !datesAreOnSameDay(startDateLocal, targetDay) ||
                    !isAfter(endDateLocal, currentUnitDateLocal)
                ) {
                    return;
                }

                // Check that time slots do not overlap (CRM time slots do not come with an end time).
                const previousSlot = timeSlots[timeSlots.length - 1];
                if (
                    previousSlot?.endDateTime &&
                    previousSlot?.endDateTime > startDateLocal
                ) {
                    return;
                }

                timeSlots.push({
                    startDateTime: startDateLocal,
                    endDateTime: endDateLocal,
                    available: true,
                    currentTimeSlot: false
                });
            }
        );

        return timeSlots;
    }

    /**
     * Update the selected tour time when it is changed.
     */
    @action
    onTourTimeChange(newTime: string): void {
        this.tourTime = newTime;
    }

    /**
     * Update the selected desired move-in date when it is changed.
     */
    @action
    onDesiredMoveInDateChange(newDate: Date): void {
        this.desiredMoveInDate = newDate;
    }

    /**
     * Get the current day in the location's time zone. If mock time is being used, this will return the mock time.
     * @returns Current day Date object.
     */
    getCurrentUnitDateLocal(timeZone: string): Date {
        return (
            this.mockTime.mockTime ??
            new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
        );
    }

    /**
     * Get end time of appointment.
     * @returns End time for the appointment.
     */
    getAppointmentEndDate(startTime: Date, appointmentLength: number): Date {
        return addMinutes(startTime, appointmentLength);
    }

    /**
     * Get the minimum allowable move-in date for the date picker. The minimum date will be the current day.
     * @returns Minimum date.
     */
    get minMoveInDate(): Date {
        const today = new Date();
        return today;
    }

    /**
     * Get the maximum allowable move-in date for the date picker. The maximum date will be a year from the current day.
     * @returns Maximum date.
     */
    get maxMoveInDate(): Date {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() + 1);
        return maxDate;
    }

    /**
     * Format a Date object as a string in the format YYYY-MM-DD.
     * @param date Date to format.
     * @returns A date string with the format YYYY-MM-DD.
     */
    toFormattedString(date: Date): string {
        return format(date, 'yyyy-MM-dd');
    }

    /**
     * Validates user input and sends it to the server if it is valid.
     */
    @action
    async next(event: Event): Promise<void> {
        event.preventDefault();

        if (!this.validateUserInputs()) {
            return;
        }

        const tourDateTime = new Date(this.tourTime);
        const desiredMoveInDate = this.desiredMoveInDate!;
        this.args.nextAction(tourDateTime, desiredMoveInDate);
    }

    /**
     * Determines whether the user inputs are valid and adds the appropriate errors to the NotificationManager.
     * @returns Boolean indicating whether the user inputs are valid.
     */
    validateUserInputs(): boolean {
        // TODO: This method may need to be updated in POINT-6942 depending on how errors will be displayed.
        if (!this.tourTime) {
            this.notificationManager.addError('Tour time is required.');
            return false;
        }

        if (!this.desiredMoveInDate) {
            this.notificationManager.addError(
                'Desired move-in date is required.'
            );
            return false;
        } else if (isBefore(this.desiredMoveInDate, this.minMoveInDate)) {
            this.notificationManager.addError(
                'Desired move-in date must not be in the past.'
            );
            return false;
        } else if (isAfter(this.desiredMoveInDate, this.maxMoveInDate)) {
            this.notificationManager.addError(
                'Desired move-in date must not be more than a year from today.'
            );
            return false;
        }

        return true;
    }
}
