import SessionService from 'ember-simple-auth/services/session';
import ENV from 'unattended-showing/config/environment';

/**
 * Class extending Ember Simple Auth's SessionService.
 * By default, ESA will redirect to the rootURL specified in environment.js when the session is invalidated.
 * This class is extended so that we can send the user to the login page upon session invalidation rather than rootURL.
 * Ideally, we would be able to provide a custom route for handleInvalidation, but this is not an available feature as of 2025-01-06.
 * A GitHub issue has been raised: https://github.com/mainmatter/ember-simple-auth/issues/2915
 */
export default class SGTSession extends SessionService {
    handleInvalidation(routeAfterInvalidation: string): void {
        const id = this.data?.contextId;
        super.handleInvalidation(
            id ? `${ENV.rootURL}${id}` : routeAfterInvalidation
        );
    }
}
