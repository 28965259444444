/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { computed } from '@ember/object';

/**
 * Component for application footer.
 */
export default class AppFooter extends Component {
    /**
     * Company name to display for who's powering the website.
     *
     * @type {String}
     */
    get poweredByName() {
        return this.args.poweredByName ?? 'Alarm.com';
    }

    /**
     * URL for website's privacy policy.
     *
     * @type {String}
     */
    @computed('args.hasPointCentralDealer')
    get privacyPolicyUrl() {
        return this.args.hasPointCentralDealer
            ? 'https://www.pointcentral.com/privacy-policy/'
            : 'https://alarm.com/privacy/';
    }

    /**
     * URL for website's terms of use.
     *
     * @type {String}
     */
    @computed('args.hasPointCentralDealer')
    get termsOfUseUrl() {
        return this.args.hasPointCentralDealer
            ? 'https://www.pointcentral.com/terms-of-use/'
            : 'https://www.alarm.com/terms_conditions.aspx/';
    }

    /**
     * URL for 'powered by' image.
     *
     * @type {String}
     */
    @computed('args.hasPointCentralDealer')
    get poweredByUrl() {
        return this.args.hasPointCentralDealer
            ? 'https://www.pointcentral.com/'
            : 'https://www.alarm.com/';
    }

    /**
     * Current year for copyright text.
     *
     * @type {Number}
     */
    copyrightYear = new Date().getFullYear();

    /**
     * Link for 'powered by' image.
     *
     * @type {String}
     */
    @computed('args.hasPointCentralDealer')
    get poweredByLogo() {
        return this.args.hasPointCentralDealer
            ? '/app/assets/images/poweredbyPC.png'
            : '/app/assets/images/powered_by_adc.png';
    }
}
