import Component from '@glimmer/component';

import type { DropdownActionsSignature } from './dropdown-actions';
import type { ColorName } from '../utils/type-utils';

export interface StatusToggleSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to display. */
        statusText: string;
        /** Optional icon to display. */
        statusIcon?: string;
        /** Optional menu items to show when status is clicked. */
        menuItems?: DropdownActionsSignature['Args']['items'];
        /** An optional status color (defaults to gray-60). */
        color?: ColorName;
    };
}

export default class StatusToggle extends Component<StatusToggleSignature> {
    /**
     * A computed CSS class based on the optional status color.
     */
    get statusColor(): string {
        return `adc-${this.args.color ?? 'gray-60'}-text`;
    }
}
