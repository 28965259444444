import templateOnlyComponent from '@ember/component/template-only';

export interface SimpleListCircleSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates the circle style and size (defaults to `numbered`) */
        type?: 'avatar' | 'numbered';
    };
    Blocks: {
        default: [];
    };
}

const SimpleListCircle = templateOnlyComponent<SimpleListCircleSignature>();
export default SimpleListCircle;
