import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * @classdesc
 * Controller for login route.
 */
export default class LoginController extends Controller {
    @service sgtSession;
    @service router;
    @service ajax;
    @service loginPendingBooking;

    /**
     * Stores messages from backend requests.
     *
     * @type {string}
     */
    requestMessage = '';

    username;
    password;

    /**
     * Attempt to authenticate user.
     *
     * @returns {Promise}
     */
    @action async login() {
        const { username, password, sgtSession } = this,
            { context } = this.model;

        try {
            // Try to authenticate user against backend.
            await sgtSession.authenticate(
                'authenticator:credentials',
                username,
                password,
                context.systemGroupId
            );
            this.router.transitionTo('context.appointment', context.id);

            // Pending appointments are appointments the user tried to book before logging
            // in that should be automatically booked upon login.
            if (this.loginPendingBooking.hasPendingAppointments) {
                await this.loginPendingBooking.processPendingAppointments();
            }
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to login. Please try again later.'
            );
        }
    }
}
