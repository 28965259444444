import Controller from '@ember/controller';
import { computed, action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getCommunitySiteMapObjectUrl } from 'unattended-showing/helpers/file-utils';

/**
 * @classdesc
 * Controller for Self-Guided Tours location page.
 */
export default class LocationController extends Controller {
    @service sgtSession;
    @service cookies;
    @service store;
    @service ajax;
    @service loginPendingBooking;
    @service checkIn;
    @service router;
    @service mockTime;

    @action
    handleImageError(event) {
        event.target.src = '../../assets/images/property-default-image.png';
    }

    /**
     * Settings for appointment-schedule component.
     *
     * @function
     * @returns {appointmentLength: Number, hoursOfOperationSchedule: List of timeblocks ({day: Number, startMinutesLocal: Number, endMinutesLocal: Number})}
     */
    @computed('model.context')
    get appointmentSettings() {
        const { appointmentLength, hoursOfOperationSchedule } =
            this.model.context;
        return {
            appointmentLength,
            hoursOfOperationSchedule
        };
    }

    /**
     * Gets user's current appointment at community or creates a new record if it does not exist.
     *
     * @function
     * @returns {models.Appointment}
     */
    @computed('context', 'model.location.hasAppointment')
    get appointment() {
        return (async () => {
            const location = await this.model.location;

            // Return existing appointment.
            if (location.hasAppointment) {
                return location.nextAppointment;
            }

            // Create a new one since one does not already exist.
            let newAppointment = this.store.createRecord('appointment');
            newAppointment.set('context', this.context);
            newAppointment.set('location', location);
            return newAppointment;
        })();
    }

    /**
     * Save new appointment. The Date passed in should be in the local timezone.
     * The Date passed to the backend should be UTC
     *
     * @function
     * @param {Date} date
     * @returns {models.Appointment|promise|null}
     */
    @action async save(date) {
        // Redirect to login route if user is not logged in.
        const context = await this.store.peekAll('context').objectAt(0),
            property = await this.model.location, // This is an ember object, timeZone is local.
            appointmentUtcDate = zonedTimeToUtc(date, property.timeZone),
            appointment = await this.appointment,
            activePendingIdVerification = property.reverificationRequired,
            geofenceEnabled = property.geofenceEnabled;

        // Try to save new appointment.
        setProperties(appointment, {
            dateTimeUtc: appointmentUtcDate,
            current: true,
            past: false,
            activePendingIdVerification: activePendingIdVerification,
            showCheckIn: geofenceEnabled,
            subdomain: context.id
        });

        if (!this.sgtSession.isAuthenticated) {
            this.loginPendingBooking.loadAppointment(appointment, property);
            this.router.transitionTo('context.login');
            return null;
        }

        // Redirect to credit card verification if user has not verified credit card and the dealer
        // requires that the credit card be verified.
        const profile = await this.store.findRecord(
            'profile',
            this.sgtSession.data.authenticated.profileId
        );

        if (context.showCCPage && !profile.creditCardValidated) {
            this.router.transitionTo('context.sign-up.verification');
            return null;
        }

        try {
            await appointment.save();
            property.reload();
            window.scrollTo(0, 0);
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to request Self-Guided Tours appointment. Please try again later.'
            );
        }

        const tourWindowStart = new Date(
            appointmentUtcDate.getTime() - 5 * 60000
        );
        const withinTourWindow =
            tourWindowStart <= (this.mockTime.mockTime ?? new Date());

        // Send the CPID verification if user needs to be verified and it is not within tour time.
        if (property.reverificationRequired && !withinTourWindow) {
            await this.checkIn.sendCheckpointIdVerification(
                appointment.id,
                false
            );
        }
    }

    /**
     * Cancel existing appointment at location.
     *
     * @function
     * @returns {models.Appointment|promise}
     */
    @action async delete() {
        let appt = await this.appointment;

        try {
            await appt.destroyRecord();
            const location = await this.model.location;
            location.reload();
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to cancel Self-Guided Tours appointment. Please try again later.'
            );
        }
    }

    /**
     * Check-in the current appointment.
     */
    @action async checkInAppointment() {
        const appointment = await this.appointment;
        const property = await appointment.location;
        const cpidEnabled = await property.checkpointIdEnabled;
        await this.checkIn.appointmentCheckIn(property, appointment);

        if (cpidEnabled) {
            setProperties(appointment, {
                showCheckIn: false
            });
        } else {
            window.location.reload();
        }
    }

    /**
     * Send user the CPID verification.
     *
     * @function
     * @returns {models.Appointment|promise}
     */
    @action async reVerify() {
        const appointment = await this.appointment;
        await this.checkIn.sendCheckpointIdVerification(appointment.id);
    }

    /**
     * Opens a new tab for the community site map.
     *
     * @function
     */
    @action async openCommunitySiteMap(ev) {
        ev.preventDefault();

        await getCommunitySiteMapObjectUrl(
            this.model.context,
            this.model.context.systemGroupId,
            this.ajax
        );

        window.open(
            this.model.context.communitySiteMapObjectUrls[
                this.model.context.systemGroupId
            ],
            '_blank',
            'noopener noreferrer'
        );
    }
}
