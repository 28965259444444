/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface VerificationCodeArgs {
    onSubmit: (code: string) => void;
    resendRoute: string;
    needHelpRoute: string;
}
export default class PhoneVerification extends Component<VerificationCodeArgs> {
    verificationCode: string[] = Array(6).fill('');
    @tracked isCodeComplete = false;

    @action
    handleInput(index: number, event: InputEvent): void {
        const input = event.target as HTMLInputElement;

        // If the input is not a single number, 0-9, clear the value.
        if (!input.value.match(/^\d$/)) {
            input.value = '';
        }

        this.verificationCode[index] = input.value;
        this.updateIsCodeComplete();
        this.updateFocus();
    }

    @action
    handleKeyDown(index: number, event: KeyboardEvent): void {
        // When the user hits backspace on an empty input box,
        // move the focus to the previous input box.
        if (event.key === 'Backspace' && index > 0) {
            const input = event.target as HTMLInputElement;
            if (input.value === '') {
                (
                    document.querySelectorAll('.digit-input')[
                        index - 1
                    ] as HTMLInputElement
                ).focus();
            }
        }
    }

    @action
    handlePaste(event: ClipboardEvent): void {
        const pasteData = event.clipboardData?.getData('text') || '';
        // If the user tries to copy and past a 6 digit code,
        // then fill in the input.
        if (pasteData.match(/^\d{6}$/)) {
            pasteData.split('').forEach((value, index) => {
                this.verificationCode[index] = value;
                // Directly set the value of the input field so the component re-renders.
                (
                    document.querySelectorAll('.digit-input')[
                        index
                    ] as HTMLInputElement
                ).value = value;
            });

            this.updateIsCodeComplete();
            this.updateFocus();
        }
        event.preventDefault();
    }

    @action
    submitCode(): void {
        if (this.isCodeComplete) {
            // Execute function passed in from parent.
            this.args.onSubmit(this.verificationCode.join(''));
        }
    }

    private updateFocus(): void {
        let nextEmptyIndex = this.verificationCode.findIndex(
            (digit) => digit === ''
        );

        // if the code has been filled in, set the focus to the last input.
        if (nextEmptyIndex === -1) {
            nextEmptyIndex = this.verificationCode.length - 1;
        }

        (
            document.querySelectorAll('.digit-input')[
                nextEmptyIndex
            ] as HTMLInputElement
        ).focus();
    }

    private updateIsCodeComplete() {
        this.isCodeComplete = this.verificationCode.every(
            (digit) => digit !== ''
        );
    }
}
