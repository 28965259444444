import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
    createRowItem,
    createNativeSubField
} from '../../components/input-form-row/index';
import { inject as service } from '@ember/service';
import { validateTextInputPassword } from '../../utils/validators';

/**
 * Controller for changing user's password.
 */
export default class ChangePasswordController extends Controller {
    @service notificationManager;
    @service sgtSession;
    @service ajax;
    @service router;

    /**
     * Query parameter.
     *
     * @type {[String]}
     */
    queryParams = ['token'];

    /**
     * Default token value.
     *
     * @type {String}
     * @default: null
     */
    @tracked token = null;

    /**
     * Returns data for template to generate required input fields.
     *
     * @function
     * @returns {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}}>, validator: {Function}, errorMessage: {String}}>}
     */
    get userPasswordInfoFields() {
        return [
            createRowItem(
                'New Password',
                [createNativeSubField('password', 'password')],
                validateTextInputPassword,
                'Password must contain at least eight characters, one number (0-9), and one capital letter.'
            ),
            createRowItem(
                'Confirm Password',
                [createNativeSubField('confirmPassword', 'password')],
                validateTextInputPassword,
                'Please enter the same password again.'
            )
        ];
    }

    /**
     * Saves new password.
     *
     * @param {models.ProfilePassword} model
     * @returns {models.ProfilePassword|promise}
     */
    @action save(model) {
        // Set token if it is provided as a query parameter to send along to server. This indicates a password reset.
        if (this.token) {
            this.model.profilePassword.set('token', this.token);
        }

        // Save new password.
        return model
            .save()
            .then(() => {
                this.token = null;
                this.router.transitionTo('context.login');
                this.notificationManager.addSuccess(
                    'Your password has been updated.'
                );
            })
            .catch((errors) => {
                return this.ajax.handleAjaxError(
                    errors,
                    'Unable to update password. Please try again later.'
                );
            });
    }
}
