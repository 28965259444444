import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * Increases the size of the text area as the user types.
 */
function resizeElement(element?: HTMLElement): void {
    if (element) {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
    }
}

export interface MultilineTextInputSignature {
    Element: HTMLTextAreaElement;
    Args: {
        /** The input value. */
        value?: string;
        /** Triggered when the user hits the enter key. */
        enter?: () => any;
        /** Triggered when the input value changes. */
        'on-change'?: (e: KeyboardEvent) => any;
    };
}

/**
 * @classdesc
 * A textarea that expands and shrinks to fit content.
 */
export default class MultilineTextInputComponent extends Component<MultilineTextInputSignature> {
    @action
    onKeyDown(e: KeyboardEvent): void {
        // If we have an @enter argument, assume we want a "submit" behavior rather than a newline.
        if (e.key === 'Enter' && this.args.enter) {
            e.preventDefault();
            this.args.enter();
        }
    }

    /**
     * Manipulates the textarea element to fit contents.
     */
    @action
    onKeyUp(e: KeyboardEvent & { target: HTMLElement }): void {
        resizeElement.call(this, e.target);
    }

    /**
     * Manipulates the textarea element to fit contents.
     */
    @action
    onFocusIn(e: FocusEvent & { target: HTMLElement }): void {
        resizeElement.call(this, e.target);
    }
}
