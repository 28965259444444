import BaseTimezoneControl from './base-timezone-control.ts';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { intlPath } from '@adc/i18n/path';

import type { Registry as ServiceRegistry } from '@ember/service';
import type { BaseTimezoneControlSignature } from './base-timezone-control';
import type { DateTimePickerComponentSignature } from './date-time-picker.ts';
import type { ButtonIconSignature } from './button/icon';

export interface SelectDateTimeComponentSignature extends BaseTimezoneControlSignature {
    Element: ButtonIconSignature['Element'];
    Args: Pick<
        DateTimePickerComponentSignature['Args'],
        'minDate' | 'maxDate' | 'showSeconds' | 'timeZone' | 'onchange'
    > & {
        /** The current selected date. */
        date?: Date;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * A button, that when clicked, opens a popup with options for choosing a single date and time.
 */
@intlPath({ module: '@adc/ui-components', path: 'select-date-time' })
export default class SelectDateTimeComponent extends BaseTimezoneControl<SelectDateTimeComponentSignature> {
    @service declare intl: ServiceRegistry['adc-intl'];

    /**
     * Indicates the date range picker popup is open.
     */
    @tracked isOpen = false;

    /**
     * Indicates the date/time selection controls are currently invalid.
     */
    @tracked isValid = false;

    /**
     * The currently selected date.
     */
    @tracked date: Date;

    constructor(owner: unknown, args: SelectDateTimeComponentSignature['Args']) {
        super(owner, args);

        this.date = args.date ?? new Date();
    }

    /**
     * The computed date range string.
     */
    @computed('args.{date,showSeconds}')
    get displayText(): string {
        const dateFmt: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };

        if (this.args.showSeconds) {
            dateFmt.second = '2-digit';
        }

        const { zone } = this;
        if (zone) {
            dateFmt.timeZone = zone;
        }

        return this.intl.formatDateTz(this.args.date, dateFmt);
    }

    /**
     * Toggles the date range picker popup opened or closed.
     */
    @action togglePicker(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Notifies consumer of newly selected date.
     */
    @action selectDateTime(): void {
        this.togglePicker();
        this.args.onchange(this.date);
    }
}
