import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { A } from '@ember/array';

export type SimpleButtonColor = 'primary' | 'safe' | 'danger' | 'stealth' | 'dark';
export type SimpleButtonStyle = 'link' | 'small';

export interface ButtonSimpleSignature {
    Element: HTMLButtonElement;
    Args: {
        /** Optional text to appear in the button. */
        text?: string;
        /** Optional text that will appear for small screen widths. */
        responsiveText?: string;
        /** The button type. */
        type?: 'button' | 'submit' | 'reset';
        /** Optional button color. */
        buttonColor?: 'primary' | 'safe' | 'danger' | 'stealth' | 'dark';
        /** Optional button style. */
        buttonStyle?: 'link' | 'small' | 'inverted';
        /** A function called when the button is clicked (easier to use `{{on 'click'}}`). */
        buttonAction?: (e: MouseEvent | KeyboardEvent) => unknown;
    };
    Blocks: {
        default: [];
    };
}

function toArray(v?: string): string[] | undefined {
    return v?.trim().split(' ').filter(isPresent);
}

export function getAnyButtonStyle(style?: string): string | undefined {
    return toArray(style)
        ?.map((v) => `btn-style-${v}`)
        .join(' ');
}

/**
 * Accepts another button style and removes anything not allowed in the simple button style.
 */
export function getSimpleButtonStyle(style?: string): SimpleButtonStyle | undefined {
    return toArray(style)
        ?.filter((v) => v === 'link' || v === 'small' || v === 'inverted')
        .join(' ') as SimpleButtonStyle;
}

/**
 * A simple button.
 */
export default class ButtonSimple extends Component<ButtonSimpleSignature> {
    /**
     * The computed CSS classes for this button based on the button color and style arguments.  Since multiple `buttonColor` values would conflict only the last passed value will be used.
     */
    get buttonCss(): string {
        const { args } = this,
            lastColor = toArray(args.buttonColor)?.pop();

        return A([lastColor && `btn-color-${lastColor.trim()}`, getAnyButtonStyle(args.buttonStyle)])
            .compact()
            .join(' ')
            .trim();
    }

    /**
     * Event handler for template button click.
     */
    @action handleClick(e: MouseEvent): void {
        this.args.buttonAction?.(e);
    }
}
