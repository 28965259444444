import { action, computed, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { ModelFrom } from '../../../../customer-site/app/utils/type-utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import Controller from '@ember/controller';

import type { Registry as ServiceRegistry } from '@ember/service';
import type Appointment from 'unattended-showing/models/appointment';
import type LocationSgt3Route from './route';
import type SGTSession from 'unattended-showing/services/sgt-session';

export default class LocationController extends Controller {
    @service declare sgtSession: SGTSession;
    @service declare ajax: ServiceRegistry['ajax'];
    @service declare router: ServiceRegistry['router'];
    @service declare store: ServiceRegistry['store'];
    @service
    declare notificationManager: ServiceRegistry['notification-manager'];

    declare model: ModelFrom<LocationSgt3Route>;

    /**
     * Settings for appointment-schedule component.
     *
     * @function
     * @returns {appointmentLength: Number, hoursOfOperationSchedule: List of timeblocks ({day: Number, startMinutesLocal: Number, endMinutesLocal: Number})}
     */
    @computed('model.context')
    get appointmentSettings() {
        const { appointmentLength, hoursOfOperationSchedule } =
            this.model.context || {};
        return {
            appointmentLength,
            hoursOfOperationSchedule
        };
    }

    /**
     * Creates a new appointment record.
     * @returns A new appointment record.
     */
    @computed('context', 'model.{context,location}', 'store')
    get appointment(): Promise<Appointment> {
        return (async () => {
            const location = await this.model.location;
            const newAppointment = this.store.createRecord('appointment', {
                context: this.model.context,
                location: location
            });
            return newAppointment;
        })();
    }

    /**
     * Steps to take when the user clicks Next on the Book a Tour screen.
     * @param tourDateTime Tour date and time selected by the user.
     * @param desiredMoveInDate Desired move-in date entered by the user.
     * @returns Void.
     */
    @action async bookTourNextAction(
        tourDateTime: Date,
        desiredMoveInDate: Date
    ): Promise<void> {
        const appointment = await this.appointment,
            property = await this.model.location,
            context = await this.store.peekAll('context').objectAt(0),
            defaultErrorMsg =
                'Unable to request Self-Guided Tours appointment. Please try again later.';

        if (!property || !context) {
            this.notificationManager.addError(defaultErrorMsg);
            return;
        }

        const appointmentUtcDate = zonedTimeToUtc(
                tourDateTime,
                property.timeZone
            ),
            activePendingIdVerification = property.reverificationRequired,
            geofenceEnabled = property.geofenceEnabled;

        setProperties(appointment, {
            dateTimeUtc: appointmentUtcDate,
            current: true,
            past: false,
            activePendingIdVerification: activePendingIdVerification,
            showCheckIn: geofenceEnabled,
            subdomain: context.id
        });

        // Redirect to the login route if user is not logged in.
        const profileId = this.sgtSession.data?.authenticated?.profileId;
        if (!this.sgtSession.isAuthenticated || !profileId) {
            this.router.transitionTo('context.login-sgt3');
            return;
        }

        // Redirect to credit card verification if user has not verified credit card
        // and the dealer requires that the credit card be verified.
        const profile = await this.store.findRecord('profile', profileId);
        if (context.showCCPage && !profile.creditCardValidated) {
            this.router.transitionTo(
                'context.sign-up-sgt3.credit-card-verification'
            );
            return;
        }

        // TODO POINT-4484 & POINT-6907: User is sent to the Review & Book page before the appointment and desired move-in date are saved.
        console.log('Tour Time:', tourDateTime);
        console.log('Desired Move-In Date:', desiredMoveInDate);
        return;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'context/location-sgt3': LocationController;
    }
}
