import BaseTimezoneControl from './base-timezone-control.ts';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import set from 'date-fns/set';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getSeconds from 'date-fns/getSeconds';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';

import type { Registry as ServiceRegistry } from '@ember/service';
import type { BaseTimezoneControlSignature } from './base-timezone-control';

export interface DateTimePickerComponentSignature {
    Element: HTMLFormElement;
    Args: BaseTimezoneControlSignature['Args'] & {
        /** The current selected date. */
        date: Date;
        /** Triggered when the user changes the date or time. */
        onchange: (date: Date) => void;
        /** Optional minimum date value. */
        minDate?: Date;
        /** Optional maximum date value. */
        maxDate?: Date;
        /** Indicates we should show seconds in the time picker. */
        showSeconds?: boolean;
        /** Called when the time/date controls are validated. */
        onValidation?: (isValid: boolean) => void;
    };
}

/**
 * @classdesc
 * A component with a smart date and smart time component, allowing users to select a single date/time.
 */
export default class DateTimePickerComponent extends BaseTimezoneControl<DateTimePickerComponentSignature> {
    @service declare intl: ServiceRegistry['adc-intl'];

    /**
     * The currently selected date.
     */
    @tracked date: Date;

    constructor(owner: unknown, args: DateTimePickerComponentSignature['Args']) {
        super(owner, args);

        this.date = args.date;
    }

    private getDateString(date?: Date): string {
        return date
            ? this.intl.formatDateTz(date, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
              })
            : '';
    }

    get minTime(): Date | undefined {
        const { minDate } = this.args;
        return this.getDateString(this.date) === this.getDateString(minDate) ? minDate : undefined;
    }

    get maxTime(): Date | undefined {
        const { maxDate } = this.args;
        return this.getDateString(this.date) === this.getDateString(maxDate) ? maxDate : undefined;
    }

    private fieldValidity = {
        date: true,
        time: true
    };

    /**
     * Validates date/time then notifies consumer of newly selected date/time.
     */
    private selectDateTime(values: Parameters<typeof set>[1], evt: InputEvent): void {
        const date = (this.date = set(this.date, values)),
            form = evt.target as HTMLFormElement;

        // Are form inputs valid?
        if (form.reportValidity()) {
            this.notifyValidity();
            this.args.onchange(date);
        }
    }

    /**
     * Notifies consumers (via the `onValidation` callback) of the current validity of the date/time controls.
     */
    private notifyValidity(): void {
        const { fieldValidity } = this;
        this.args.onValidation?.(fieldValidity.date && fieldValidity.time);
    }

    /**
     * Update the date value.
     */
    @action changeDate(newDate: Date, evt: InputEvent): void {
        this.fieldValidity.date = true;
        this.selectDateTime(
            {
                year: getYear(newDate),
                month: getMonth(newDate),
                date: getDate(newDate)
            },
            evt
        );
    }

    /**
     * Update the time value.
     */
    @action changeTime(newTime: Date, evt: InputEvent): void {
        this.fieldValidity.time = true;
        this.selectDateTime(
            {
                hours: getHours(newTime),
                minutes: getMinutes(newTime),
                seconds: getSeconds(newTime)
            },
            evt
        );
    }

    /**
     * Called when one of the date/time inputs is invalid.
     */
    @action handleInvalid(evt: Event): void {
        this.fieldValidity[(evt.target as HTMLInputElement).getAttribute('type') === 'time' ? 'time' : 'date'] = false;
        this.notifyValidity();
    }
}
