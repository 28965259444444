import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';
import SGTSession from 'unattended-showing/services/sgt-session';
import Context from '../../models/context';

interface ProhibitAuthentication {
    prohibitAuthentication(route: string): void;
}

type SessionServiceWithProhibitAuthentication = SGTSession &
    ProhibitAuthentication;

export default class ForgotPasswordSgt3Route extends Route {
    @service declare router: ServiceRegistry['router'];
    @service declare sgtSession: SessionServiceWithProhibitAuthentication;

    beforeModel() {
        this.sgtSession.prohibitAuthentication('context.appointment');
    }

    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
