import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * Calculate hex color code for luminance changes. Taken from: http://www.sitepoint.com/javascript-generate-lighter-darker-color/.
 *
 * @param {String} hex
 * @param {Number} lum
 *
 * @returns {String}
 *
 * @private
 */
function changeBrightness(hex, lum) {
    // Validate hex string.
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex.replace(/(.)/g, '$1$1');
    }

    lum = lum || 0;

    // Convert to decimal and change luminosity.
    let rgb = '#',
        c;
    for (let i = 0; i < 3; ++i) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

/**
 * @classdesc
 * Parent route for any pages that need dealer context.
 */
export default class ContextRoute extends Route {
    @service notificationManager;
    @service errorReporting;
    @service store;
    @service router;

    /**
     * @returns {{Context: (models.Context|Promise)}}
     */
    async model(param) {
        return {
            context: await this.store.findRecord('context', param.context_id)
        };
    }

    afterModel(model) {
        const dealerBrandedColor = model.context.backgroundColor;
        if (dealerBrandedColor) {
            const rootStyle = document.documentElement.style;
            rootStyle.setProperty(`--dealer-branded-color`, dealerBrandedColor);
            rootStyle.setProperty(
                `--dealer-branded-color-hover`,
                changeBrightness(dealerBrandedColor, -0.2)
            );
        }

        // Update favIcon.
        const favIcon = document.querySelector("link[rel*='icon']");
        if (favIcon) {
            favIcon.href = `/app/assets/images/${
                model.context.hasPointCentralDealer ? 'pcfavicon' : 'adcfavicon'
            }.png`;
        }

        // Set context for Sentry error reporting
        this.errorReporting.setContext({
            user: {
                id: model.context.id
            },
            tags: model.context.toJSON(),
            extra: { model: model.context }
        });
    }

    @action error(error) {
        const context = this.store.peekAll('context').objectAt(0);

        if (context) {
            let errorMessages = error?.errors?.errors ?? error?.errors ?? [];

            // Make sure errorMessages is an array.
            if (typeof errorMessages === 'string') {
                errorMessages = [errorMessages];
            }

            // Show the error message first since we're handling the error here.
            errorMessages.forEach((error) =>
                this.notificationManager.addError(error.detail ?? error)
            );
            this.router.transitionTo('context.not-found', 'not-found');
        } else {
            // If no context was found, the error cannot be handled here. Let the application error route handle it.
            return true;
        }

        // Let error reporting service report it.
        throw error;
    }
}
