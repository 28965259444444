import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { intlPath } from '@adc/i18n/path';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';

import type AjaxService from '@adc/ajax/services/adc-ajax';

export interface FileUploadSignature {
    Element: HTMLDivElement;
    Args: {
        /** The maximum allowed file size, in bytes. */
        maxFileSize?: number;
        /** A CSV string indicating the accepted file types. */
        accept?: string;
        /** The URI endpoint to upload the file to. */
        endpoint?: string;
        /** Triggered after the file has uploaded tp the `@endpoint`. */
        fileUploaded?: (result: Record<string, unknown>) => void;
        /** Triggered when the user has selected a file. */
        fileChanged?: () => void;
        /** Triggered when the file upload has failed. */
        uploadFailed?: (error: any) => void;
        /** Triggered when the selected file size is greater than the indicates `@maxFileSize`. */
        fileTooLarge?: () => void;
    };
    Blocks: {
        default: [];
    };
}

/**
 * Default max file size in bytes.
 *
 * @private
 */
const DEFAULT_MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;

/**
 * Upload the specified file.
 *
 * @private
 */
async function uploadFile(this: FileUploadComponent, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    Object.assign(this, {
        isUploading: true,
        fileName: file.name
    });

    try {
        const result = await postFormData.call(this, this.args.endpoint, formData);
        this.args.fileUploaded?.(result);
    } catch (error) {
        this.fileName = '';
        this.args.uploadFailed?.(error);
    }
}

/**
 * Send the specified formData to the endpoint.
 *
 * @param endpoint - The api endpoint that should be POSTed to.
 * @param formData - A FormData object that contains the file to upload.
 *
 * @private
 */
function postFormData(
    this: FileUploadComponent,
    endpoint: string,
    formData: FormData
): Promise<Record<string, unknown>> {
    const { defaultHeaders, apiBaseUrl } = this.ajax;

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${apiBaseUrl}/${endpoint}`);
        xhr.setRequestHeader('AjaxRequestUniqueKey', defaultHeaders.ajaxrequestuniquekey ?? '');

        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(JSON.parse(xhr.responseText));
                return;
            }

            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };

        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };

        xhr.send(formData);
    });
}

/**
 * Reset the upload field.
 *
 * @private
 */
function resetUploadField(this: FileUploadComponent, fileInput: HTMLInputElement): void {
    fileInput.files = null;
    fileInput.value = '';
    this.isUploading = false;
}

/**
 * @classdesc
 * Upload a file using a POST request to an endpoint.
 */
@intlPath({ module: '@adc/ui-components', path: 'file-upload' })
export default class FileUploadComponent extends Component<FileUploadSignature> {
    @service declare ajax: AjaxService;

    /**
     * Max file size that this field will support.
     */
    get maxFileSize(): number {
        return this.args.maxFileSize ?? DEFAULT_MAX_FILE_SIZE_BYTES;
    }

    /**
     * Name of the currently selected file.
     *
     * @ignore
     */
    @tracked fileName = '';

    /**
     * Is a file currently being uploaded?
     *
     * @ignore
     */
    @tracked isUploading = false;

    /**
     * Upload a file when a user selects one to be uploaded.
     */
    @action
    async fileSelected(evt: Event): Promise<void> {
        const fileInput = evt.target as HTMLInputElement;
        if (!fileInput?.files?.length) {
            return;
        }

        // Call the fileChanged action if there is one.
        this.args.fileChanged?.();

        const [file] = fileInput.files;

        // The file is too large, call the fileTooLarge action if there is one.
        if (file.size > this.maxFileSize) {
            this.args.fileTooLarge?.();
        } else {
            await uploadFile.call(this, file);
        }

        resetUploadField.call(this, fileInput);
    }

    @action
    selectFile(e: MouseEvent & { target: HTMLButtonElement }): void {
        e.preventDefault();
        e.target.closest('.file-upload')?.querySelector<HTMLInputElement>('input[type="file"]')?.click();
    }

    /**
     * For a11y, handles a space or enter while focusing on the file upload button.
     */
    @action onKeyUp(evt: KeyboardEvent & { target: HTMLInputElement }): void {
        if (isEnterOrSpaceCode(evt.code)) {
            evt.preventDefault();
            evt.target.closest('.file-upload')?.querySelector<HTMLInputElement>('input[type="file"]')?.click();
        }
    }
}
