import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';
import { getAnyButtonStyle, getSimpleButtonStyle } from './simple.ts';

import type { ButtonSimpleSignature } from './simple';
import type { SvgSymbolSignature } from '@adc/svg-system/components/svg-symbol';

export interface ButtonIconSignature {
    Element: ButtonSimpleSignature['Element'];
    Args: Omit<ButtonSimpleSignature['Args'], 'text' | 'buttonStyle'> & {
        /** Buttons text (required for icon buttons). */
        text: string;
        /** The name of the icon to render (minus the "icn-" prefix). */
        icon: SvgSymbolSignature['Args']['icon'];
        /** Indicates if only the icon should be visible in the button. */
        iconOnly?: boolean;
        /** Optional button style. */
        buttonStyle?: 'link' | 'small' | 'reversed' | 'inverted';
    };
    Blocks: ButtonSimpleSignature['Blocks'];
}

/**
 * A button that includes an icon with the text.
 */
export default class ButtonIcon extends Component<ButtonIconSignature> {
    @service declare media: { isMobile: true };

    constructor(owner: unknown, args: ButtonIconSignature['Args']) {
        super(owner, args);

        ['text', 'icon'].forEach((name: 'text' | 'icon') => {
            assert(
                `[@adc/ui-components] The "@${name}" argument is required by the <Button::Icon /> component`,
                isPresent(this.args[name])
            );
        });
    }

    /**
     * Removes icon buttons styles.
     */
    get simpleButtonStyle(): ReturnType<typeof getSimpleButtonStyle> {
        return getSimpleButtonStyle(this.args.buttonStyle);
    }

    /**
     * Returns the icon "reversed" button style if present.
     */
    get reversedCss(): string {
        return (this.args.buttonStyle?.includes('reversed') && getAnyButtonStyle('reversed')) || '';
    }

    /**
     * The text to display in the button (varies based on responsiveness).
     */
    @computed('args.{text,responsiveText}', 'media.isMobile')
    get buttonText(): string {
        const { text, responsiveText } = this.args;
        return (responsiveText !== undefined && this.media.isMobile ? responsiveText : text) ?? '';
    }
}
