import ProtectedRoute from 'unattended-showing/classes/protected-route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Context from '../../../models/context';

import type { Registry as ServiceRegistry } from '@ember/service';

interface ContextModel {
    context: Promise<Context>;
}

/**
 * @classdesc
 * Verification sub-route for user sign-up.
 */
export default class CreditCardVerificationRoute extends ProtectedRoute {
    @service declare router: ServiceRegistry['router'];
    @service declare store: Store;

    /**
     * Creates new credit card verification model.
     */
    async model() {
        const contextModel = this.modelFor('context') as ContextModel;
        const context = await contextModel.context;
        const countries = await this.store.findAll('country');
        return {
            context,
            countries
        };
    }

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context || !model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
