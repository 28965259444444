import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class GuestPhoneVerificationController extends Controller {
    @action
    handleVerificationCodeSubmit(): void {
        // Handle the submitted verification code.
        // TODO in POINT-4475
    }

    @action
    handleResendCode(code: string): void {
        console.log('User entered code: ' + code);
        // Handle the resend code action.
        // TODO in POINT-4475
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'context/guest-phone-verification': GuestPhoneVerificationController;
    }
}
